import { configureStore } from '@reduxjs/toolkit';
import bagItemsReducer from './bagItemsSlice.js';

const store = configureStore({
  reducer: {
    bagItems: bagItemsReducer
  }
});

export default store;
