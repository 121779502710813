import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';

import playerObject from '../../image/player.png';
import enemyObject from '../../image/enemy.png';

import _1xIcon from '../../image/icons/1x.png';
import pauseIcon from '../../image/icons/pause_icon.png';
import speedIcon from '../../image/icons/speed_icon.png';
import playIcon from '../../image/icons/play_icon.png';
import BowIcon from '../../image/shop/Bow.png';

import WinBattleScreen from '../../components/WinBattleScreen/WinBattleScreen';

import './Arena.scss';

const StatusBarPlayer = ({ label, value, color }) => (
  <div className="status-bar">
    <p>{label}</p>
    <div className="status-field">
      <span style={{ width: `${value}%`, backgroundColor: color }}></span>
    </div>
  </div>
);

const StatusBarEnemy = ({ label, value, color }) => (
  <div className="status-bar">
    <p>{label}</p>
    <div className="status-field">
      <span style={{ width: `${value}%`, backgroundColor: color }}></span>
    </div>
  </div>
);

const calculateTotalDamage = (items) => {
  return items.reduce((total, item) => total + (parseInt(item.stats) || 0), 0);
};

const Arena = () => {
  const { bagItems } = useSelector((state) => state.bagItems);

  const enemyItems = useMemo(() => [
    {
      id: 1,
      attackFrequency: 5,
      effect: "none",
      img: BowIcon,
      name: "Bow",
      stats: "4" // Adjusted to just numeric value
    }
  ], []);

  const [isBattleOver, setIsBattleOver] = useState(false);
  const [battleResult, setBattleResult] = useState(null);
  const [showDamage, setShowDamage] = useState({
    playerDamage: null,
    enemyDamage: null,
  });
  const [playerState, setPlayerState] = useState({
    move: false,
    health: 100
  });
  const [enemyState, setEnemyState] = useState({
    move: false,
    health: 100
  });

  const [isPaused, setIsPaused] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [blueLineWidth, setBlueLineWidth] = useState(0); // New state for blue-line width

  // Animation for player and enemy
  const [playerSpring, setPlayerSpring] = useSpring(() => ({ transform: 'translateX(0px)', config: { duration: 100 } }));
  const [enemySpring, setEnemySpring] = useSpring(() => ({ transform: 'translateX(0px)', config: { duration: 100 } }));

  useEffect(() => {
    if (playerState.health <= 0 || enemyState.health <= 0) {
      setIsBattleOver(true);
      if (playerState.health > 0) {
        setBattleResult('Winner');
      } else {
        setBattleResult('Loser');
      }
      return;
    }
  
    const totalPlayerDamage = calculateTotalDamage(bagItems);
    const totalEnemyDamage = calculateTotalDamage(enemyItems);
  
    const attackPlayer = () => {
      if (playerState.health <= 0 || enemyState.health <= 0) {
        setIsBattleOver(true);
        setBattleResult(playerState.health > 0 ? 'Winner' : 'Loser');
        return;
      }
  
      // Sharp movement
      setPlayerSpring.start({ transform: 'translateX(140px)', immediate: true });
      setEnemySpring.start({ transform: 'translateX(-140px)', immediate: true });
  
      setEnemyState((prev) => ({
        ...prev,
        health: Math.max(prev.health - totalPlayerDamage, 0),
        move: true // Trigger animation for enemy
      }));
      setShowDamage({
        playerDamage: totalPlayerDamage,
        enemyDamage: null,
      });
  
      setTimeout(() => {
        setShowDamage((prev) => ({
          ...prev,
          playerDamage: null,
        }));
        setPlayerSpring.start({ transform: 'translateX(0px)', immediate: true });
      }, 100 / speed); // Adjust duration for speed
  
      setPlayerState((prev) => ({
        ...prev,
        health: Math.max(prev.health - totalEnemyDamage, 0),
        move: true // Trigger animation for player
      }));
      setShowDamage({
        playerDamage: null,
        enemyDamage: totalEnemyDamage,
      });
  
      setTimeout(() => {
        setShowDamage((prev) => ({
          ...prev,
          enemyDamage: null,
        }));
        setEnemySpring.start({ transform: 'translateX(0px)', immediate: true });
      }, 100 / speed); // Adjust duration for speed
  
      setTimeout(() => {
        setEnemyState((prev) => ({
          ...prev,
          move: false
        }));
        setPlayerState((prev) => ({
          ...prev,
          move: false
        }));
      }, 200 / speed); // Adjust total duration for speed
    };
  
    const playerItem = bagItems[0] || {};
    const enemyItem = enemyItems[0];
  
    const playerAttackInterval = (playerItem.attackFrequency || 1) * 1000 / speed; // Adjust interval for speed
    const enemyAttackInterval = enemyItem.attackFrequency * 1000 / speed;
  
    const playerIntervalId = setInterval(() => {
      if (!isPaused) attackPlayer();
    }, playerAttackInterval);
  
    const enemyIntervalId = setInterval(() => {
      if (!isPaused) attackPlayer();
    }, enemyAttackInterval);
  
    // Update blue-line width over time
    const updateBlueLineWidth = () => {
      setBlueLineWidth(prev => Math.min(prev + 1, 100)); // Increase width gradually
    };
    
    const widthIntervalId = setInterval(updateBlueLineWidth, 1000); // Adjust interval as needed

    return () => {
      clearInterval(playerIntervalId);
      clearInterval(enemyIntervalId);
      clearInterval(widthIntervalId); // Clear interval on cleanup
    };
  }, [playerState.health, enemyState.health, bagItems, enemyItems, playerSpring, enemySpring, setPlayerSpring, setEnemySpring, isPaused, speed]);

  const handlePlayPause = () => {
    setIsPaused(prev => !prev); // Toggle pause
  };
  
  const handleSpeedChange = () => {
    setSpeed(prev => (prev === 1 ? 2 : 1)); // Toggle speed between 1x and 2x
  };

  return (
    <div className="arena">
      {isBattleOver && <WinBattleScreen result={battleResult} />}
      <div className={`arena-container ${isBattleOver ? 'overlay' : ''}`}>
        <div className="arena-bags-container">
          <div className="player-bag">
            {bagItems.length === 0 ? <p>No items in bag</p> : bagItems.map(item =>
              <img key={item.id} src={item?.img} alt={item.name} />
            )}
          </div>
          <div className="enemy-bag">
            {enemyItems.map(item =>
              <img key={item.id} src={item?.img} alt={item.name} />
            )}
          </div>
        </div>
        <div className="arena-main-block">
          <div className="arena-main-block_info">
            <animated.img
              className={`player-object`}
              src={playerObject}
              alt="Player"
              style={playerSpring}
            />
            {showDamage.enemyDamage && (
              <div className="damage-display enemy-damage">
                -{showDamage.enemyDamage} dmg
              </div>
            )}

            <animated.img
              className={`enemy-object`}
              src={enemyObject}
              alt="Enemy"
              style={enemySpring}
            />
            {showDamage.playerDamage && (
              <div className="damage-display player-damage">
                -{showDamage.playerDamage} dmg
              </div>
            )}
            <div className="arena-main-block_info-play">
              <div className="arena-main-block_info-play_top">
                <span className='blue-line' style={{ width: `${blueLineWidth}%` }}>
                  <img src={playIcon} alt="Play" onClick={handlePlayPause} />
                </span>
              </div>
              <div className="arena-main-block_info-play_actions">
                <img src={_1xIcon} alt="1x" onClick={() => setSpeed(1)} />
                <img src={pauseIcon} alt="Pause" onClick={handlePlayPause} />
                <img src={speedIcon} alt="Speed" onClick={handleSpeedChange} />
              </div>
            </div>
            <div className="arena-main-block_info-palyer">
              <div className="player-nickname">
                <p>User</p>
              </div>
              <div className="player-health-info">
                <StatusBarPlayer label="Health" value={playerState.health} color="blue" />
              </div>
            </div>
            <div className="arena-main-block_info-enemy">
              <div className="enemy-nickname">
                <p>Computer</p>
              </div>
              <div className="enemy-health-info">
                <StatusBarEnemy label="Health" value={enemyState.health} color="red" />
              </div>
            </div>
            <div className="arena-main-block_subinfo">
              <div className="buffs-debuffs">
                <div className="buff"></div>
                <div className="debuffs"></div>
              </div>
              <div className="log"></div>
              <div className="buffs-debuffs">
                <div className="buff"></div>
                <div className="debuffs"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Arena;
