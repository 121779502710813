import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import SDMIcon from '../../image/icons/sdm_icon.png';
import pageBellsSound from '../../assets/sounds/dzin.mp3';
import { generateFinalShopItems } from '../../helper/randomizerShopItems';
import { HoverBox } from '../HoverBox/HoverBox';
import { toast } from 'react-toastify';
import './ShopShells.scss';


const ShopShells = () => {
  const [generatedShopItems, setGeneratedShopItems] = useState([]);
  const [activeItemIndex, setActiveItemIndex] = useState(null);

  useEffect(() => {
    refreshItems();
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshItems = (index = null) => {
    let lastClickTime = 0;
    const now = Date.now();
    const cooldown = 1000;
    if (now - lastClickTime < cooldown) {
      showToast('Please wait a second before clicking again.');
    } else {
      lastClickTime = now;
      const music = new Audio(pageBellsSound);
      music.play().catch(error => console.log('Sound error:', error));

      const roundNumber = 1;
      const numberOfSelections = 5;
      const finalItems = generateFinalShopItems(roundNumber, numberOfSelections);
      if (index !== null) {
        setGeneratedShopItems(prevItems => {
          const newItems = [...prevItems];
          newItems[index] = finalItems[index]; // Refresh specific item
          return newItems;
        });
      } else {
        setGeneratedShopItems(finalItems); // Refresh all items
      }
    }
  };

  const showToast = message => {
    toast.error(message);
  };

  // Draggable item with a custom drag preview
  const ShopItem = ({ item, index }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
      type: 'ITEM',
      item: { ...item, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (draggedItem, monitor) => {
        if (monitor.didDrop()) {
          // Remove the item from the shop items after drop
          setGeneratedShopItems(prevItems => {
            const newItems = [...prevItems];
            newItems.splice(draggedItem.index, 1); // Remove the dragged item from the list
            return newItems;
          });
        }
      },
    }));

    useEffect(() => {
      const img = new Image();
      img.src = item?.img;
      preview(img, { captureDraggingState: true });
    }, [item, preview]);

    return (
      <div
        className={`item${index + 1}-container`}
        onMouseEnter={() => setActiveItemIndex(index)}
        onMouseLeave={() => setActiveItemIndex(null)} 
      >
        <img ref={drag} className={`item${index + 1}`} src={item?.img} alt={`Item ${index + 1}`} style={{ opacity: isDragging ? 0.3 : 1 }} />
        <div className="badge">{item?.price}</div>

        {activeItemIndex === index && (
          <HoverBox item={item} />
        )}
      </div>
    );
  };

  return (
    <div className="shop-shells">
      <div className="shop-shells-image-block">
        {generatedShopItems && (
          <div className="shop-shells-image">
            {generatedShopItems.map((item, index) => (
              <ShopItem key={index} item={item} index={index} />
            ))}
          </div>
        )}
      </div>

      <div className="refresh">
        <div className="refresh-content" onClick={() => refreshItems()}>
          <div className="score">
            <img src={SDMIcon} alt="SDM Icon" />
            <span>100</span>
          </div>
          <p>refresh</p>
          <p>items</p>
        </div>
      </div>
    </div>
  );
};

export default ShopShells;
