import OldShirtImg from '../../image/shop/Old_shirt.png';
import StrawHatImg from '../../image/shop/Straw_hat.png';
import BandanaImg from '../../image/shop/Bandana.png';
import ShacklesImg from '../../image/shop/Shackles.png';

// For items without images, you can import a placeholder or leave it as null
// import PlaceholderImg from '../../image/shop/placeholder.png'; 

export const tier1Armor = [
  {
    name: "Old shirt",
    stats: "+1 armor stack",
    effect: "+1 armor stack",
    space: "2x2",
    price: 10,
    usageFrequency: 1.25,
    img: OldShirtImg
  },
  {
    name: "Straw hat",
    stats: "+5hp",
    effect: "+5hp",
    space: "3x2",
    price: 15,
    usageFrequency: null,
    img: StrawHatImg
  },
  {
    name: "Straw hat",
    stats: "+5hp",
    effect: "+5hp",
    space: "3x2",
    price: 15,
    usageFrequency: null,
    img: StrawHatImg
  },
  {
    name: "Bandana",
    stats: "-5% negative effects",
    effect: "-5% negative effects",
    space: "2x2",
    price: 20,
    usageFrequency: null,
    img: BandanaImg
  },
  {
    name: "Shackles",
    stats: "-2 melee dmg taken stack",
    effect: "-2 melee dmg taken stack",
    space: "2x2",
    price: 10,
    usageFrequency: 1.25,
    img: ShacklesImg
  },
  {
    name: "Straw hat",
    stats: "+5hp",
    effect: "+5hp",
    space: "3x2",
    price: 15,
    usageFrequency: null,
    img: StrawHatImg
  },
];
