import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tavern from './pages/Tavern/Tavern';
import Arena from './pages/Arena/Arena';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Tavern />} />
        <Route path="/arena" element={<Arena />} />
      </Routes>
      
      <ToastContainer />
    </Router>
  );
};

export default App;
