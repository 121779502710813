import { shopItemChances, tier1Ranged, tier1Items, tier1Armor, tier1Bags, tier1Melee } from '../config/index.js';

export function generateFinalShopItems(roundNumber, numberOfSelections = 5) {

    function getRandomItem(chances) {
        const totalChance = chances.reduce((sum, item) => sum + item.chance, 0);
        const random = Math.random() * totalChance;

        let cumulativeChance = 0;
        for (const item of chances) {
            cumulativeChance += item.chance;
            if (random < cumulativeChance) {
                return item.item;
            }
        }
    }

    function getRandomObjectFromTier(tierArray) {
        const randomIndex = Math.floor(Math.random() * tierArray.length);
        return tierArray[randomIndex];
    }

    function replaceItemsWithTierObjects(selectedItems) {
        return selectedItems.map(item => {
            switch (item) {
                case 'Weapon Ranged':
                    return getRandomObjectFromTier(tier1Ranged);
                case 'Weapon Melee':
                    return getRandomObjectFromTier(tier1Melee);
                case 'Item':
                    return getRandomObjectFromTier(tier1Items);
                case 'Armor':
                    return getRandomObjectFromTier(tier1Armor);
                case 'Bag':
                    return getRandomObjectFromTier(tier1Bags);
                default:
                    return item;
            }
        });
    }

    const selectedItems = Array.from({ length: numberOfSelections }, () => getRandomItem(shopItemChances));
    const finalItems = replaceItemsWithTierObjects(selectedItems);
    
    return finalItems;
}
