export const HoverBox = ({ item }) => {
  return (
    <div className='hover-box'>
      <div className="hover-box_container">
        <div className="hover-box-title">
          <p>{item?.name}</p>
          <span>{item?.type}</span>
        </div>
        <div className="hover-box-info">
          <div className="hover-box-info_item">
            <p>Effect:</p>
            <span>{item?.effect}</span>
          </div>
          <div className="hover-box-info_item">
            <p>{item?.usageAttack ? 'Usage' : 'Attack '} frequency:</p>
            <span>{(item?.usageAttack ? item?.usageAttack : item?.attackFrequency) || '-'}</span>
          </div>
          <div className="hover-box-info_item">
            <p>{item?.size ? 'Size' : 'Space'}:</p>
            <span>{(item?.space || item?.size)}</span>
          </div>
        </div>
        <div className="hover-box-rarity">
          <p>{item?.rarity}</p>
        </div>
      </div>
    </div>
  );
};