import React, { useEffect, useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBagItemsR } from '../../store/bagItemsSlice'; // Import the action creator

import energyIcon from "../../image/icons/stamina_icon.png";
import healthIcon from "../../image/icons/health_icon.png";
import sdmIcon from "../../image/icons/sdm_icon.png";
import marketIcon from "../../image/icons/market_icon.png";
import settingsIcon from "../../image/icons/settings_btn.png";
import StartBattleBtn from "../../image/start_battle_btn.png";
import pageFlipSound from '../../assets/sounds/buttle.mp3';
import './InventoryBlock.scss';
import { toast } from 'react-toastify';

const InventoryBlock = ({ storageItems, setStorageItems, bagItems, setBagItems }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentBagItems = useSelector((state) => state.bagItems.bagItems);
  const [draggedItemSize, setDraggedItemSize] = useState(null); // State for dragged item size

  const handleStartClick = () => {
    const music = new Audio(pageFlipSound);

    if (currentBagItems.length) {
      music.play();
      navigate('/arena');
    } else {
      toast.warn('Firstly equip a backpack');
    }
  };

  const handleDropToBag = (item) => {
    if (item.type === 'bag') {
      toast.warn('Cannot drop items of type "bag" into the main bag.');
      return;
    }

    // Remove the item from storage
    const updatedStorageItems = storageItems.filter(storedItem => storedItem.name !== item.name);
    setStorageItems(updatedStorageItems);

    // Add the item to the bag
    const updatedBagItems = [...currentBagItems, item];
    
    setBagItems(updatedBagItems);
    dispatch(setBagItemsR(updatedBagItems)); // Dispatch the updated array
  };

  const handleDropToStorage = (item) => {
    // Remove the item from the bag
    const updatedBagItems = currentBagItems.filter(bagItem => bagItem.name !== item.name);
    setBagItems(updatedBagItems);
    dispatch(setBagItemsR(updatedBagItems)); // Dispatch the updated array

    // Add the item to storage
    setStorageItems(prevItems => [...prevItems, item]);
  };

  // Drop target for the bag area
  const [{ isOverBag }, dropBag] = useDrop({
    accept: 'ITEM',
    drop: (item) => {
      console.log('Item dropped into bag:', item);
      handleDropToBag(item);
    },
    collect: (monitor) => ({
      isOverBag: monitor.isOver(),
    }),
  });

  // Drop target for the storage area
  const [{ isOverStorage }, dropStorage] = useDrop({
    accept: 'ITEM',
    drop: (item) => {
      handleDropToStorage(item);
    },
    collect: (monitor) => ({
      isOverStorage: monitor.isOver(),
    }),
  });

  // Drag source for items in storage
  const DraggableStorageItem = ({ item }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: 'ITEM',
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => {
      const img = new Image();
      img.src = item?.img;
      preview(img, { captureDraggingState: true });
    }, [item, preview]);

    // Update dragged item size
    useEffect(() => {
      if (item) {
        // Assuming item.size contains width and height in grid cells
        setDraggedItemSize(item.size);
      }
    }, [item]);

    return (
      <div className="stored-item">
        <img ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} src={item?.img} alt="Stored Item" />
      </div>
    );
  };

  // Drag source for items in the bag
  const DraggableBagItem = ({ item }) => {
    const [{ isDragging }, drag, preview] = useDrag({
      type: 'ITEM',
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    useEffect(() => {
      const img = new Image();
      img.src = item?.img;
      preview(img, { captureDraggingState: true });
    }, [item, preview]);

    // Update dragged item size
    useEffect(() => {
      if (item) {
        // Assuming item.size contains width and height in grid cells
        setDraggedItemSize(item.size);
      }
    }, [item]);

    return (
      <div className="bag-item" ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <img src={item?.img} alt="Bag Item" />
      </div>
    );
  };

  // Highlight grid cells based on dragged item size
  const getHighlightedGridCells = () => {
    if (!draggedItemSize) return [];
    
    const cells = [];
    const { width, height } = draggedItemSize;

    for (let row = 0; row < height; row++) {
      for (let col = 0; col < width; col++) {
        cells.push({ row, col });
      }
    }
    return cells;
  };

  const highlightedCells = getHighlightedGridCells();

  return (
    <div className="inventory-block">
      <div className="inventory-block-header">
        <div className="inventory-block-header_left">
          <div className="stamina"><p>stamina usage: </p><span>low</span></div>
          <div className="inventory-block-header_left-frame">
            <div className="energy">
              <img src={energyIcon} alt="" />
              <span className="amount">400</span>
            </div>
            <div className="health">
              <img src={healthIcon} alt="" />
              <span className="amount">400</span>
            </div>
            <div className="sdm">
              <img src={sdmIcon} alt="" />
              <span className="amount">400</span>
            </div>
          </div>
        </div>
        <div className="inventory-block-header_right">
          <img src={marketIcon} alt="" />
          <img src={settingsIcon} alt="" />
        </div>
      </div>

      <div className="inventory-block-bag">
        <div className="grid">
          {[...Array(80)].map((_, index) => {
            const row = Math.floor(index / 10);
            const col = index % 10;
            const isHighlighted = highlightedCells.some(cell => cell.row === row && cell.col === col);
            return (
              <div 
                key={index} 
                className={`grid-item ${isHighlighted ? 'highlight' : ''}`} 
              />
            );
          })}
        </div>
        <div className={`bag-block ${isOverBag ? 'highlight' : ''}`} ref={dropBag}>
          {currentBagItems?.map((item, index) => (
            <DraggableBagItem key={index} item={item} />
          ))}
        </div>
      </div>

      <div className={`storage ${isOverStorage ? 'highlight' : ''}`} ref={dropStorage}>
        {storageItems.map((item, index) => (
          <DraggableStorageItem key={index} item={item} />
        ))}
      </div>

      <div className="banjo" onClick={handleStartClick}>
        <img src={StartBattleBtn} alt="Start Battle" />
      </div>
    </div>
  );
};

export default InventoryBlock;
