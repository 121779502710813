import React from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ShopShells from '../../components/ShopShells/ShopShels';
import InventoryBlock from '../../components/InventoryBlock/InventoryBlock';

import './Tavern.scss';

const Tavern = () => {
  const [storageItems, setStorageItems] = React.useState([]); 
  const [bagItems, setBagItems] = React.useState([]); 
  
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="tavern">
        <ShopShells />
        <InventoryBlock
          storageItems={storageItems}
          setStorageItems={setStorageItems}
          bagItems={bagItems}
          setBagItems={setBagItems}
        />
      </div>
    </DndProvider>
  );
};

export default Tavern;
