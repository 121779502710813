import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bagItems: []
};

const bagItemsSlice = createSlice({
  name: 'bagItems',
  initialState,
  reducers: {
    setBagItemsR: (state, action) => {
      state.bagItems = action.payload; // Make sure the action payload is a plain array of items
    }
  }
});

export const { setBagItemsR } = bagItemsSlice.actions;

export default bagItemsSlice.reducer;
