import Pouch from '../../image/shop/Pouch.png';
import Coyote_pelt_bag from '../../image/shop/Coyote_pelt_bag.png';
import Emptied_rat from '../../image/shop/Emptied_rat.png';


export const tier1Bags = [
    {
      name: "Pouch",
      size: "3x1",
      effect: "none",
      price: 15,
      img: Pouch,
      type: 'bag'
    },
    {
      name: "Coyote pelt bag",
      size: "2x3",
      effect: "none",
      price: 12,
      img: Coyote_pelt_bag,
      type: 'bag'
    },
    {
      name: "Emptied rat",
      size: "2x1",
      effect: "none",
      price: 10,
      img: Emptied_rat,
      type: 'bag'
    }
  ];
  