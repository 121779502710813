import Broken_bottle from '../../image/shop/Broken_bottle.png';
import Fork from '../../image/shop/Fork.png';
import Sickle from '../../image/shop/Sickle.png';
import BrassKnuckles from '../../image/shop/Brass_knuckles.png';
import SharpenedBone from '../../image/shop/Sharpened_bone.png';


// Tier 1 Melee Items
export const tier1Melee = [
    {
      name: 'Broken bottle',
      stats: '3dmg',
      effect: '15% to cause bleed',
      space: '2x1',
      price: 15,
      attackFrequency: 1,
      img: Broken_bottle
    },
    {
      name: 'Fork',
      stats: '1dmg',
      effect: 'none',
      space: '1x1',
      price: 10,
      attackFrequency: 0.5,
      img: Fork
    },
    {
      name: 'Sickle',
      stats: '4dmg',
      effect: 'none',
      space: '2x2',
      price: 15,
      attackFrequency: 1.25,
      img: Sickle
    },
    {
      name: 'Brass knuckles',
      stats: '2dmg',
      effect: '15% to cause stun',
      space: '2x1',
      price: 15,
      attackFrequency: 0.75,
      img: BrassKnuckles
    },
    {
      name: 'Sharpened bone',
      stats: '1dmg',
      effect: 'none',
      space: '2x1',
      price: 5,
      attackFrequency: 0.5,
      img: SharpenedBone
    }
  ];