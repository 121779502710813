 import Throwing_axe from '../../image/shop/Throwing_axe.png';
 import Bow from '../../image/shop/Bow.png';
 import Throwing_spear from '../../image/shop/Throwing_spear.png'
 import Crossbow from '../../image/shop/Crossbow.png'
 import Slingshot from '../../image/shop/Slingshot.png'

 
 // Tier 1 Ranged Items
 export const tier1Ranged = [
    {
      name: 'Throwing axe',
      stats: '3dmg',
      effect: '15% to cause bleed',
      space: '2x1',
      price: 15,
      staminaUsage: null, // Значення для витрати стаміни невідоме
      attackFrequency: 1,
      img: Throwing_axe
    },
    {
      name: 'Bow',
      stats: '4dmg',
      effect: 'none',
      space: '3x1',
      price: 15,
      staminaUsage: null, // Значення для витрати стаміни невідоме
      attackFrequency: 1.25,
      img: Bow
    },
    {
      name: 'Crossbow',
      stats: '4dmg',
      effect: '15% to ignore armor',
      space: '3x2',
      price: 15,
      staminaUsage: null, // Значення для витрати стаміни невідоме
      attackFrequency: 1.50,
      img: Crossbow
    },
    {
      name: 'Throwing Spear',
      stats: '3dmg',
      effect: 'none',
      space: '3x1',
      price: 10,
      staminaUsage: null, // Значення для витрати стаміни невідоме
      attackFrequency: 1.25,
      img: Throwing_spear
    },
    {
      name: 'Slingshot',
      stats: '2dmg',
      effect: '15% to cause stun',
      space: '2x1',
      price: 15,
      staminaUsage: null, // Значення для витрати стаміни невідоме
      attackFrequency: 1,
      img: Slingshot
    }
  ];
  