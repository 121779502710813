import React from 'react';
import { useNavigate } from 'react-router-dom'; // or 'react-router-dom' if using React Router v5
import './WinBattleScreen.scss';

import winImage from "../../image/win_r.png"
import sdmIcon from "../../image/icons/sdm_icon.png";
import staminaIcon from "../../image/icons/stamina_icon.png";

const WinBattleScreen = ({ result }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };
    

    return (
        <div className="win-battle-screen" onClick={handleClick}>
            <div className="block-image">
                <div className="win-battle-screen_title">{result === 'Winner' ? 'Victory!' : 'You Lose!'}</div>
                <img src={winImage} alt="" />
            </div>
            <div className="block-paper">
                <div className="rewards">
                    <p>Rewards: </p>
                    <div style={{display: 'flex', gap:'8px', alignItems: 'center'}}><p>+25 </p><img src={sdmIcon} alt="sdmIcon" /></div>
                    <div style={{display: 'flex', gap:'8px', alignItems: 'center'}}><p>+1 </p><img src={staminaIcon} alt="staminaIcon" /></div>
                </div>
                <div className="wins">
                    <p>Wins: </p>
                </div>
                <div className="lives">
                    <p>Lives left: </p>
                </div>
            </div>
            <div className="continue-text">Click to continue</div>
        </div>
    );
};

export default WinBattleScreen;
