import Potato from '../../image/shop/Potato.png';
import Pipe from '../../image/shop/Pipe.png';
import Beer from '../../image/shop/Beer.png';
import CactusJuice from '../../image/shop/Cactus_juice.png';
import DirtyWater from '../../image/shop/Dirty_water.png';


export const tier1Items = [
    {
      name: "Potato",
      effect: "Heals 1hp per/s",
      space: "1x1",
      price: 5,
      // staminaUsage: 1,
      usageFrequency: 1,
      img: Potato
    },
    {
      name: "Pipe",
      effect: "Ranged dmg+1",
      space: "1x1",
      price: 5,
      // staminaUsage: 1,
      usageFrequency: 1,
      img: Pipe
    },
    {
      name: "Beer",
      effect: "Melee +1 dmg",
      space: "1x1",
      price: 5,
      // staminaUsage: 1,
      usageFrequency: 1,
      img: Beer
    },
    {
      name: "Cactus juice",
      effect: "Stamina regen+1/s",
      space: "1x1",
      price: 5,
      // staminaUsage: "none",
      usageFrequency: 1,
      img: CactusJuice
    },
    {
      name: "Dirty water",
      effect: "none",
      space: "1x1",
      price: 5,
      // staminaUsage: 1,
      usageFrequency: 1,
      img: DirtyWater
    }
  ];
  